export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Random X.AI",
    slogan: "slogan",
    icon: "/favicon.ico",
    navbarItems: [
        {
            title: "ChineseNamesGenerator.title",
            children: [
                {
                    icon: 'raw',
                    title: "ChineseNamesGenerator.title",
                    description: "ChineseNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator",
                },
                {
                    icon: 'female',
                    title: "ChineseFemaleNamesGenerator.title",
                    description: "ChineseFemaleNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator/female",
                },
                {
                    icon: 'male',
                    title: "ChineseMaleNamesGenerator.title",
                    description: "ChineseMaleNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator/male",
                },
                {
                    icon: 'girl',
                    title: "ChineseGirlNamesGenerator.title",
                    description: "ChineseGirlNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator/girl",
                },
                {
                    icon: 'boy',
                    title: "ChineseBoyNamesGenerator.title",
                    description: "ChineseBoyNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator/boy",
                },
                {
                    icon: 'cat',
                    title: "ChineseCatNamesGenerator.title",
                    description: "ChineseCatNamesGenerator.slogan",
                    href: "/tools/chinese-names-generator/cat",
                },
                {
                    icon: 'cross-cultural',
                    title: "CrossCulturalNameGenerator.title",
                    description: "CrossCulturalNameGenerator.slogan",
                    href: "/tools/chinese-names-generator/cross-cultural",
                }
            ]
        },
        {
            icon: "explore",
            title: "ChineseNamesGenerator.explore.title",
            href: "/tools/chinese-names-generator/explore",
        },
        {
            icon: "info",
            title: "ChineseNamesGenerator.information.title",
            href: "/tools/chinese-names-generator/information",
        },
    ],
}
