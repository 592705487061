"use client"
import {useTranslations} from "next-intl"
import {Accordion, AccordionItem} from "@nextui-org/accordion";
import {cn} from "@repo/utils/utils";
import {FAQItem} from "../types";
import {FC} from "react";


type FAQProps = {
    items: FAQItem[]
    className?: string
}

const FAQ: FC<FAQProps> = ({className, items}) => {
    const t = useTranslations("FAQs")
    return (
        <section className={cn("my-10 px-4 max-w-screen-xl mx-auto", className)}>
            <h1 className="text-black text-2xl font-bold mb-4">{t("title")}</h1>
            <Accordion isCompact defaultExpandedKeys="all" itemClasses={{
                title: "text-black font-bold text-lg",
                content: "py-5"
            }}>
                {items.map((item, index) => (
                    <AccordionItem key={index} title={`${index < 9 ? '0' : ''}${index + 1}. ${item.question}`}>
                        {item.answer}
                    </AccordionItem>
                ))}
            </Accordion>
        </section>
    )
}
FAQ.displayName = "FAQ"
export default FAQ